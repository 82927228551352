import React, { useState, useEffect } from 'react';
import { 
  BrowserRouter as Router, Routes, Route 
} from 'react-router-dom';

import './App.css';
import LandingPage from "./components/LandingPage";
import Illustrator from './components/Illustrator';
import Author from './components/Author';
import NavBar from './components/Navbar'
import Photos from './components/Photos'


function App() {
  return (
    <Router>
      <NavBar/>
      <Routes>
        
        <Route 
          path="/" 
          element={
            <LandingPage
            />}
          />
        <Route 
          path="/author" 
          element={
            <Author
            />}
          />
        <Route 
          path="/illustrator" 
          element={
            <Illustrator
            />}
          />
        <Route 
          path="/photos" 
          element={
            <Photos
            />}
          />
        </Routes>
      </Router>
  );
}

export default App;
