import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaInstagram } from 'react-icons/fa';


export default function Navbar (props){

  const[pancake, setPancake] = useState("")
  const[pancakeClick, setPancakeClick] = useState(false)


  let handleScreenChange = () => {
    window.innerWidth < 900
    ? setPancake(true)
    : setPancake(false)
  }

  useEffect(() => {
    handleScreenChange();
    window.addEventListener("resize", handleScreenChange)
    return () => {
      window.removeEventListener("resize", handleScreenChange);
    };
  }, []);
  

  let handlePancakeClick = () => {
    pancakeClick === false
      ? setPancakeClick(true)
      : setPancakeClick(false)
  }

 
  let linkStyle= {
      textDecoration: 'none',
      color: '#1D3557',
      background: 'none',
      border: 'none',
      fontFamily: '"Montserrat",sans-serif',
      fontSize: '1rem',
      margin: '10px',
      cursor: 'pointer',
  }

  let buttonStyle= {
    textDecoration: 'none',
    color: '#1D3557',
    background: 'none',
    border: 'none',
    fontFamily: '"Montserrat",sans-serif',
    fontSize: '1rem',
    fontWeight: "600",
    margin: '10px',
    cursor: 'pointer',
  }
  
  let buyStyle= {
      textDecoration: 'none',
      color: '#1D3557',
      background: 'none',
      border: 'none',
      fontFamily: '"Montserrat",sans-serif',
      fontWeight: '600',
      fontSize: '1rem',
      margin: '10px',
      cursor: 'pointer',
  }


  let headerStyle = {
    width: '94vw',
    height: '40px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 3vw 0px 3vw',
    borderBottom: '0.5px solid #cccccc',
    color: '#fff',
    background: '#fff',
    zIndex: '10',
    position: 'fixed',
    top: '0',
    left: '0',
    opacity: '0.92',
  }

  let instaStyle = {
    fontSize: '20px',
  }

  let navHeader =
      <div>
        <h2 style={{
          fontFamily: '"Montserrat",sans-serif',
          fontSize: '1.5rem',
          padding: '0',
        }}
        >
          <Link to={
            "/"
          }
            style={{
              textDecoration: 'none',
              color: '#1D3557',
              background: 'none',
              border: 'none',
              fontFamily: '"Montserrat",sans-serif',
              fontWeight: '400',
              fontSize: '1.5rem',
              cursor: 'pointer',
            }}
            >
              Wild Midwife
          </Link>
        </h2>
      </div>


  return(
    <div>
      {pancake === true
        ? pancakeClick === true
        ?
            <div style={{
            width: '100vw',
            height: '100vh',
            zIndex: '1',
            background: 'rgba(0, 0, 0, 0.6)',
            position: 'absolute',
            top: '0',
            left: '0',
            }}
            className="dontPrint"
            onClick={()=> handlePancakeClick()}
            >
            <header style={headerStyle}>
                {navHeader}
                <nav style={{
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'fixed',
                    top: '0',
                    right: '0',
                    listStyle: 'none',
                    background: '#FFF',
                    padding: '30px',
                    zIndex: '2',
                }}>
                    <li style={{
                    margin: '20px 40px 20px 0',
                    }}>
                      <button
                        style={buttonStyle}
                        onClick={() => window.location = "https://www.amazon.com/Midwife-Wild-Frontier-Melissa-Tyler/dp/B0CMJLVZCJ/ref=sr_1_1?crid=A1II8658W90E&keywords=midwife+of+the+wild+frontier&qid=1700675437&sprefix=midwife+of+the+wild+frontier%2Caps%2C606&sr=8-1"}
                      >
                        BUY
                      </button>
                    </li>
                    <li style={{
                    margin: '20px 40px 20px 0',
                    }}>
                    <Link to={"/photos"}
                        style={linkStyle}
                    >
                        Patty Pictures
                    </Link>
                    </li>
                    <li style={{
                      margin: '20px 40px 20px 0',
                      }}>
                      <Link to={"/author"}
                          style={linkStyle}
                      >
                          Author
                      </Link>
                    </li>
                    <li style={{
                      margin: '20px 40px 20px 0',
                      }}>
                      <Link to={"/illustrator"}
                          style={linkStyle}
                      >
                          Illustrator
                      </Link>
                    </li>
                    <li style={{
                    margin: '25px 40px 20px 0',
                    }}>
                    <button
                        style={buttonStyle}
                        onClick={() => window.location = "https://www.instagram.com/wildfrontiermidwife/"}
                      >
                        <FaInstagram 
                          style={instaStyle}
                        />
                      </button>
                    </li>
                </nav>
                </header>
            </div>

          :
            <header
              style={headerStyle}
              className="dontPrint"
              >
              {navHeader}
              <nav
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  listStyle: 'none',
                }}
                onClick={()=> handlePancakeClick()}>
                <li style={{
                  margin: '20px 10px 20px 0',
                }}>
                  <FaBars
                    style={{
                        textDecoration: 'none',
                        color: '#1D3557',
                        background: 'none',
                        border: 'none',
                        fontFamily: '"Montserrat",sans-serif',
                        fontSize: '2rem',
                    }}
                   />
                </li>
              </nav>
            </header>
          : 
            <header
              style={headerStyle}
              className="dontPrint"
              >
              <h2 style={{
                fontFamily: '"Montserrat",sans-serif',
                fontSize: '1.5rem',
                padding: '0',
              }}
              >
                <Link to={
                   "/"
                }
                  style={{
                    textDecoration: 'none',
                    color: '#1D3557',
                    background: 'none',
                    border: 'none',
                    fontFamily: '"Montserrat",sans-serif',
                    fontWeight: '400',
                    fontSize: '1.5rem',
                    cursor: 'pointer',
                  }}
                  >
                    Wild Midwife
                </Link>
              </h2>
                <nav style={{
                  display: 'flex',
                  marginRight: '-15px',
                  listStyle: 'none',
                }}>
                  <li style={{
                    margin: 'auto 15px',
                  }}>
                    <button
                        style={buttonStyle}
                        onClick={() => window.location = "https://www.amazon.com/Midwife-Wild-Frontier-Melissa-Tyler/dp/B0CMJLVZCJ/ref=sr_1_1?crid=A1II8658W90E&keywords=midwife+of+the+wild+frontier&qid=1700675437&sprefix=midwife+of+the+wild+frontier%2Caps%2C606&sr=8-1/"}
                      >
                        BUY
                    </button>
                  </li>
                  <li style={{
                    margin: 'auto 15px',
                  }}>
                    <Link to={"/photos"}
                      style={linkStyle}
                    >
                      Patty Pictures
                    </Link>
                  </li>
                  <li style={{
                    margin: 'auto 15px',
                  }}>
                    <Link to={"/author"}
                      style={linkStyle}
                    >
                      Author
                    </Link>
                  </li>
                  <li style={{
                    margin: 'auto 15px',
                  }}>
                    <Link to={"/illustrator"}
                      style={linkStyle}
                    >
                      Illustrator
                    </Link>
                  </li>
                  <li style={{
                    margin: '25px 40px 20px 0',
                    }}>
                      <button
                        style={buttonStyle}
                        onClick={() => window.location = "https://www.instagram.com/wildfrontiermidwife/"}
                      >
                        <FaInstagram 
                          style={instaStyle}
                        />
                      </button>
                    </li>
                </nav>
              </header>
      }
      <div style={{margin: '40px 0 0 0'}}></div>
    </div>
  )
}



