import React from 'react'

export default function Photos() {

    
    let photos = [
        {
            name: 'aristotle_book.jpeg', 
            text: "Patty's medical text (Aristotle)"
        },
        {
            name: 'example_loom.jpeg', 
            text: 'Example of a loom'
        },
        {
            name: 'fetal_presentations.jpeg', 
            text: "Patty's medical text (Fetal presentations)"
        },
        {
            name: 'patty_bountiful_home.jpeg', 
            text: "Patty's home in Bountiful, Utah"
        },
        {
            name: 'patty_sessions_academy.jpeg', 
            text: 'Patty Sessions Academy'
        },
        {
            name: 'patty_sessions_later_photo.jpeg', 
            text: 'Older Patty Sessions'
        },
        {
            name: 'patty_sessions_photo.jpeg', 
            text: 'Patty Sessions'
        },
        {
            name: 'pattys_birthplace_home.jpeg', 
            text: "Patty's birthplace home"
        }
    ]

    let pStyle ={
        fontSize: '24px',
        textAlign: 'center',
        
    }

    let cardStyle = {
        background: '#EFEFEF',
        border: 'none',
        borderRadius: '10px',
        padding: '1px 0 40px 0',
        margin: '20px 8px 0px 8px',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, .2), 0 3px 10px 0 rgba(0, 0, 0, .15)',
        width: '440px',
      }

  return (
    <div style={{display: 'flex'}}>
        <div style={{width: '7vw'}}></div>
        <div style={{
            width: '86vw', 
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            margin: '60px 0 300px 0',
            }}
        >

            {
                photos.map((m,i) => (
                    <div
                        key={i}
                        style={{display: 'flex', justifyContent: 'center'}}
                    >
                        <div
                            style={cardStyle}
                        >
                            <p style={pStyle}>{m.text}</p>
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <img
                                    src={m.name}
                                    style={{
                                        width: '400px',
                                    }}
                                />
                            </div>
                            
                        </div>  
                    </div>
                ))
            }
        </div>

        <div style={{width: '7vw'}}></div>
    </div>
  )
}
