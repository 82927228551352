// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;400;600;800&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `


/* button:hover {
    opacity: 1 !important;
    text-decoration: none !important;
    color: #007bff !important;
    font-weight: 400 !important;
} */

*:focus {
  outline: none !important;
}

.blueLink:hover {
  text-decoration: underline !important;
}

*{
  font-family: "Montserrat",sans-serif;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
   color: #1D3557;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

 @media print {
   .printEl {
       width: 12in;
       /* width: 12in; was what it was initially and may need to change it back */
       height: 11in;
   }

   .dontPrint {
   display: none !important;
 }
   .iFrame {
   display: none !important;
  }

  .topHeader p {
  color: #000 !important;
 }

}

.restaurantMenu {
    box-shadow: 0 10px 6px -6px #777;
}

.logo { /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

li {
  margin: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":";;;AAMA;;;;;GAKG;;AAEH;EACE,wBAAwB;AAC1B;;AAEA;EACE,qCAAqC;AACvC;;AAEA;EACE,oCAAoC;EAEpC,iCAAiC;EAChC,yBAAyB;GACzB,cAAc;AACjB;;AAEA,gCAAgC;AAChC;;EAEE,wBAAwB;EACxB,SAAS;AACX;;AAEA,YAAY;AACZ;EACE,0BAA0B;AAC5B;;CAEC;GACE;OACI,WAAW;OACX,0EAA0E;OAC1E,YAAY;GAChB;;GAEA;GACA,wBAAwB;CAC1B;GACE;GACA,wBAAwB;EACzB;;EAEA;EACA,sBAAsB;CACvB;;AAED;;AAEA;IAGY,gCAAgC;AAC5C;;AAEA,QACmC,qBAAqB;EACtD,uBAAuB;AACzB;;AAEA;EACE,WAAW;AACb","sourcesContent":["\n\n\n@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;400;600;800&display=swap');\n\n\n/* button:hover {\n    opacity: 1 !important;\n    text-decoration: none !important;\n    color: #007bff !important;\n    font-weight: 400 !important;\n} */\n\n*:focus {\n  outline: none !important;\n}\n\n.blueLink:hover {\n  text-decoration: underline !important;\n}\n\n*{\n  font-family: \"Montserrat\",sans-serif;\n  color-adjust: exact;\n  -webkit-print-color-adjust: exact;\n   print-color-adjust: exact;\n   color: #1D3557;\n}\n\n/* Chrome, Safari, Edge, Opera */\ninput::-webkit-outer-spin-button,\ninput::-webkit-inner-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}\n\n/* Firefox */\ninput[type=number] {\n  -moz-appearance: textfield;\n}\n\n @media print {\n   .printEl {\n       width: 12in;\n       /* width: 12in; was what it was initially and may need to change it back */\n       height: 11in;\n   }\n\n   .dontPrint {\n   display: none !important;\n }\n   .iFrame {\n   display: none !important;\n  }\n\n  .topHeader p {\n  color: #000 !important;\n }\n\n}\n\n.restaurantMenu {\n    -webkit-box-shadow: 0 10px 6px -6px #777;\n       -moz-box-shadow: 0 10px 6px -6px #777;\n            box-shadow: 0 10px 6px -6px #777;\n}\n\n.logo {\n  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */\n  filter: grayscale(100%);\n}\n\nli {\n  margin: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
