import React, { useState, useEffect } from 'react';

export default function LandingPage() {

    const[screenSize, setScreenSize]=useState('big')

    useEffect(() => {
        window.scrollTo(0, 0)
        if(window.innerWidth < 900){
            setScreenSize('small')
        } else {
            setScreenSize('big')
        }
      }, [])


    let titleStyle = {
        fontSize: '42px',
        fontWeight: '400',
        textAlign: 'center',
        margin: '80px 0 0 0'
    }
    
    let subtitleStyle = {
        fontSize: '28px',
        fontWeight: '400',
        textAlign: 'center',
        margin: '10px 0 0 0'
    }

    let pStyle ={
        fontSize: '18px',
    }


    if(screenSize === 'big'){
        return (
            <div style={{display: 'flex'}}>
                <div style={{width: '7vw'}}></div>
                <div style={{
                    width: '86vw', 
                    display: 'flex', 
                    justifyContent: 'center',
                    margin: '0 0 300px 0',
                    }}
                >
                    <div>
                        <h1 style={titleStyle}>
                            Patty Sessions
                        </h1>
                        <h2 style={subtitleStyle}>
                            Midwife of the American Frontier
                        </h2>
                        <div style={{
                            display: 'flex', 
                            justifyContent: 'center',
                            margin: '40px 0 20px 0',
                            }}>
                            <img
                                src='pattyCover.jpeg'
                                style={{
                                    width: '600px'
                                }}
                            />
                        </div>
                        <div style={{display: 'flex', justifyContent:'center'}}>
                            <div style={{width: '800px'}}>
                                <p style={pStyle}>
                                    Patty Bartlett Session, born over 220 years ago in the obscure town of Bethel, Maine, blazed trails through the wild American west as well as the cultural attitudes of her time. She didn’t back down when asked to learn and become a midwife, becoming the de facto doctor in many rural settings throughout her life, delivering nearly 4000 babies.
                                </p>
                                <p style={pStyle}>
                                    She joined one of the new religions growing out of the American continent, and was empowered to organize and bless those around her – rights typically reserved for men. Patty later, marched across the plains of the Midwest, past the border of the United States, and into the Rocky Mountains, helping deliver babies and care for the sick along the way - all while confronting the abandonment she felt from her husband who became a polygamist.
                                </p>
                                <p style={pStyle}>
                                    She saved, and toiled, and invested, reinventing and rebuilding herself at each step of her life, becoming so strong that women and men in the community would lean on her for support and were unable to knock her down. Patty saw opportunity for growth when life confronted her with its challenges. Her determination and grit turned her into one of the great pioneer women of American history. Follow her journey on 
                                        <a
                                            href="https://www.instagram.com/wildfrontiermidwife/" 
                                            style={{
                                                textDecoration: 'underline',
                                                margin: '0 0 0 5px',
                                                color: '#1D3557',
                                        }}
                                        >
                                             instagram
                                            </a>.
                                </p>
                            </div>
                        </div>
                        
                        
                    </div>
                    <div></div>
                </div>
                <div style={{width: '7vw'}}></div>
            </div>
          )
    } else if (screenSize === 'small') {
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ margin: '0 0 10px 0' }}>
              <h1 style={{ fontSize: '3rem', fontWeight: '400', textAlign: 'center' }}>
                Patty Sessions
              </h1>
              <h2 style={{ fontSize: '2rem', fontWeight: '400', textAlign: 'center' }}>
                Midwife of the American Frontier
              </h2>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img
                src='pattyCover.jpeg'
                style={{ width: '100%', maxWidth: '400px', height: 'auto' }}
              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
              <div style={{ width: '90%', maxWidth: '800px' }}>
                <p style={pStyle}>
                    Patty Bartlett Session, born over 220 years ago in the obscure town of Bethel, Maine, blazed trails through the wild American west as well as the cultural attitudes of her time. She didn’t back down when asked to learn and become a midwife, becoming the de facto doctor in many rural settings throughout her life, delivering nearly 4000 babies.
                </p>
                <p style={pStyle}>
                    She joined one of the new religions growing out of the American continent, and was empowered to organize and bless those around her – rights typically reserved for men. Patty later, marched across the plains of the Midwest, past the border of the United States, and into the Rocky Mountains, helping deliver babies and care for the sick along the way - all while confronting the abandonment she felt from her husband who became a polygamist.
                </p>
                <p style={pStyle}>
                    She saved, and toiled, and invested, reinventing and rebuilding herself at each step of her life, becoming so strong that women and men in the community would lean on her for support and were unable to knock her down. Patty saw opportunity for growth when life confronted her with its challenges. Her determination and grit turned her into one of the great pioneer women of American history. Follow her journey on 
                        <a
                            href="https://www.instagram.com/wildfrontiermidwife/" 
                            style={{
                                textDecoration: 'underline',
                                margin: '0 0 0 5px',
                                color: '#1D3557',
                        }}
                        >
                                instagram
                            </a>.
                </p>
              </div>
            </div>
          </div>
        );
      }
  
}
