import React, { useEffect } from 'react';

export default function Illustrator() {


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  let subtitleStyle = {
    fontSize: '28px',
    fontWeight: '400',
    textAlign: 'left',
    margin: '30px 0 0 0'
}

let pStyle ={
    fontSize: '18px',
    margin: '10px 0 0 0',
    maxWidth: '800px',
}

  return (
    <div style={{display: 'flex'}}>
        <div style={{width: '7vw'}}></div>
        <div style={{
            width: '86vw', 
            display: 'flex', 
            justifyContent: 'center',
            margin: '60px 0 300px 0',
            }}
        >
          <div 
            style={{}}
          >
            <div>
              <img
                src='illustrator.jpeg'
                style={{
                  height: '300px',
                  borderRadius: '30px',
                }}
              />
            </div>
            <div>
              <p style={subtitleStyle}>Luciana Maruca</p>
              <p style={pStyle}>
                Luciana started drawing during childhood and never stopped. At some point, she tried to "get serious" and graduated as a graphic designer at Palermo University (Buenos Aires) where she currently teaches layout and composition. But, graphic design didn't fulfill all her artistic needs, so Luciana went back to college and graduated as a comic book designer. She's been coloring, drawing and writing comic books ever since.
              </p>
            </div>
          </div>

        </div>
        <div style={{width: '7vw'}}></div>
    </div>
  )
}
