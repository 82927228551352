import React, { useEffect } from 'react';

export default function Author() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  let subtitleStyle = {
    fontSize: '28px',
    fontWeight: '400',
    textAlign: 'left',
    margin: '30px 0 0 0'
}

let pStyle ={
    fontSize: '18px',
    margin: '10px 0 0 0',
    maxWidth: '800px',
}

  return (
    <div style={{display: 'flex'}}>
        <div style={{width: '7vw'}}></div>
        <div style={{
            width: '86vw', 
            display: 'flex', 
            justifyContent: 'center',
            margin: '60px 0 300px 0',
            }}
        >
          <div 
            style={{}}
          >
            <div>
              <img
                src='author.jpeg'
                style={{
                  height: '300px',
                  borderRadius: '30px',
                }}
              />
            </div>
            <div>
              <p style={subtitleStyle}>Melissa Tyler</p>
              <p style={pStyle}>
                Melissa Tyler, Patty’s 4th great granddaughter, was driven to understand why her ancestor made the choices she did. She poured over Patty’s diaries, family histories, and retraced her steps through Maine in order to make sense of her polyandrous and polygamist grandmother.
              </p>
            </div>
          </div>

        </div>
        <div style={{width: '7vw'}}></div>
    </div>
  )
}
